import { env } from "md-base-tools/env";
import { mapState } from "vuex";
import Error from "@@/common/Error.vue";
import { getThreeLevelList, activityExchange } from "@/api";
// eslint-disable-next-line no-unused-vars
import { List } from "vant";
import BigImage from "@@/luckycards/bigImage.vue";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    Error,
    List,
    BigImage
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  data() {
    return {
      env,
      showError: false,
      showMessage: "",
      loading: true,
      miniProgram: "",
      card_name: "",
      level_name: this.$route.query.activity_name,
      nav: [{
        level_name: this.$route.query.activity_name
      }],
      second_relation: this.$route.query.second_relation,
      activity_name: this.$route.query.activity_name,
      card_id: this.$route.query.card_id,
      card_detail_list: [],
      order_id_list: [],
      exItem: {},
      page: 1,
      timer: null,
      total: 0,
      page_size: 20,
      repeat: false,
      is_next: false,
      showDia: false,
      hasCheckAll: false,
      bigImageVisible: false,
      arrival_time: "",
      allTypeNum: this.$route.query.allTypeNum,
      nowNum: this.$route.query.allTypeNum,
      bigInitIndex: 0,
      img_list: [],
      get_card_id: 0
    };
  },
  beforeMount() {},
  async mounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = 0;
    window.userHeader.titleText(this.activity_name);
    if (this.activity_name && this.card_id && this.second_relation) {
      await this.getInfo();
    }
  },
  unmounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = "1.6rem";
  },
  methods: {
    jump() {
      this.$router.push("/hd/card_activity");
    },
    jumpCard() {
      // this.$router.push(
      //   `/luckycards/container_detail?second_relation=${this.second_relation}&activity_id=${this.exItem.activity_id}&activity_name=${this.exItem.activity_name}`
      // );
      this.$router.push(`/luckycards/container`);
    },
    closeDia() {
      //   this.showDia = false;
    },
    // 搜索
    async onSearch() {
      await this.getInfo(1);
    },
    async onClickButton() {
      await this.getInfo(1);
    },
    // 确定选择
    async open() {
      if (!this.repeat) {
        this.repeat = true;
        if (this.nowNum == this.allTypeNum) {
          const option = {
            card_order_ids: this.order_id_list.join(","),
            default_exchange: 1,
            exchange_level_name: this.level_name,
            get_card_id: this.card_id
          };
          if (this.card_detail_list.length >= this.nowNum) {
            delete option.default_exchange;
          }
          const {
            status,
            data,
            message
          } = await activityExchange(option);
          console.log(status, data, message);
          if (status != 0) {
            let showMessage = message || "暂无数据";
            mdToast(showMessage);
            return;
          } else {
            this.exItem = data;
            this.showDia = true;
            this.repeat = false;
          }
        } else {
          mdToast("数量不够");
          this.repeat = false;
          throw "数量不够";
        }
      }
    },
    // 点击卡片
    checkCard(order_id, can_deliver_goods) {
      if (!can_deliver_goods) {
        return false;
      }
      if (this.order_id_list.includes(order_id)) {
        let index = this.order_id_list.indexOf(order_id);
        this.order_id_list.splice(index, 1);
        this.nowNum--;
      } else {
        if (this.nowNum < this.allTypeNum) {
          this.nowNum++;
          console.log(this.nowNum, this.allTypeNum);
          this.order_id_list.push(order_id);
        }
      }
    },
    // 获取卡包
    async getInfo(lockPage) {
      let endPage = lockPage ? lockPage : this.page;
      let option = {
        card_name: this.card_name,
        level_name: this.level_name,
        second_relation: this.second_relation,
        page: endPage,
        page_size: this.page_size,
        select_id: ''
      };
      const {
        status,
        data,
        message
      } = await getThreeLevelList(option);
      if (status != 0) {
        this.showError = true;
        this.showMessage = message || "暂无数据";
        return;
      } else if (data && data.total == 0 && data.list && data.list.length == 0 && this.page == 1) {
        this.total = data.total;
        this.loading = false;
        this.showError = false;
        this.card_detail_list.splice(0, this.card_detail_list.length);
        this.showMessage = "暂无数据";
        return;
      } else {
        this.showError = false;
        this.total = data.total;
        if (this.page == 1) {
          this.card_detail_list.splice(0, this.card_detail_list.length);
          this.arrival_time = data.list[0].arrival_time;
        }
        if (this.total < this.allTypeNum) {
          mdToast("数量不够");
          this.nowNum = this.total;
        }
        this.card_detail_list.push(...data.list);
        data.list.forEach(ele => {
          if (!this.order_id_list.includes(ele.card_order_id) && ele.can_deliver_goods && this.order_id_list.length < this.nowNum) {
            this.order_id_list.push(ele.card_order_id);
          }
        });
        this.loading = false;
        this.is_next = data.is_next;
      }
    },
    onLoad() {
      if (!this.is_next) {
        this.loading = false;
        return false;
      }
      if (this.loading && this.card_detail_list.length < this.total) {
        this.loading = false;
        this.timer;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.page++;
          this.getInfo();
        }, 100);
      } else {
        this.loading = false;
      }
    },
    // 切换tab
    changeNav(status) {
      this.level_name = status;
      this.card_detail_list.splice(0, this.card_detail_list.length);
      this.page = 1;
      this.loading = true;
      console.log("change_nav");
      this.getInfo(1);
    },
    showBigImage(index) {
      this.img_list = [];
      this.card_detail_list.forEach(ele => {
        ele.showBackImg = false;
        this.img_list.push(ele);
      });
      this.bigInitIndex = index;
      this.bigImageVisible = true;
    }
  }
};